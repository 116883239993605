import {
  CalculatorQuestionCategory,
  CalculatorQuestionSession,
} from "@prisma/client";
import {
  createAsyncThunk,
  createSlice,
  isAnyOf,
  PayloadAction,
} from "@reduxjs/toolkit";
import {
  CalculatorSession,
  DataEntry,
  DataEntryWithRelation,
  PostAnswer,
} from "../../types/Co2CalculatorAnswerTypes";
import {
  getCarFactorForTypeAndFuel,
  getValueFromAnswerValue,
} from "../util/calculator";
import { TSelectCurrentQuestion } from "../util/prismaHelper";
import { RootState } from "./store";

export type CalculatorStoreState = {
  data: CalculatorSession.Data;
  answers: CalculatorSession.Answers;
  summary: CalculatorSession.Summary;
  currentQuestion: TSelectCurrentQuestion | null;
  session:
    | Omit<CalculatorQuestionSession, "data" | "answers" | "summary">
    | null
    | undefined;
  nextQuestionId: string | null;
  previousQuestionId: string | null;
};

const asyncUpdateAnswer = createAsyncThunk(
  "local/updateAnswer",
  async (data: Parameters<typeof _postAnswer>[0], thunkAPI) => {
    try {
      const state = (thunkAPI.getState() as RootState).calculator;
      let value = data.value;
      if (!value) {
        value = {
          value: {},
          _meta: {},
        };
      }
      const questionId = data.qId;
      // console.log("UpdateAnswer:", value, state.answers[questionId]);
      if (typeof value.value === "string" || typeof value.value === "number") {
      } else {
        value.value = Object.assign(
          {},
          state.answers[questionId]?.value,
          value.value
        );
        value._meta = Object.assign(
          {},
          state.answers[questionId]?._meta,
          value._meta
        );
      }
      const data1 = { ...data, value: value };
      // console.log("updateAnswer", data, data1, state.answers[questionId], value);
      const resp = await _postAnswer(data1);
      return { data: data1, resp };
    } catch (e) {
      console.error(e);
    }
  }
);

const asyncReplaceAnswer = createAsyncThunk(
  "local/replaceAnswer",
  async (data: Parameters<typeof _postAnswer>[0]) => {
    const resp = await _postAnswer(data);
    console.log("replaceAnswer", data, resp);
    return { data, resp };
  }
);

const initialState: CalculatorStoreState = {
  currentQuestion: null,
  session: undefined,
  data: {},
  answers: {},
  summary: {},
  nextQuestionId: null,
  previousQuestionId: null,
};

const lSlice = createSlice({
  name: "local",
  initialState,
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(asyncUpdateAnswer.fulfilled, asyncReplaceAnswer.fulfilled),
      (state, action) => {
        if (!action.payload) return;

        if (action.payload.resp) {
          state.nextQuestionId = action.payload.resp.nextQuestionId;
        }
        if (state.session) {
          state.session.skippedQuestionIds =
            action.payload.resp.skippedQuestionIds;

          for (let qId of action.payload.resp.skippedQuestionIds) {
            delete state.answers[qId];
            delete state.data[qId];
          }
        }

        const value = action.payload.data.value;
        const questionId = action.payload.data.qId;
        if (
          (questionId === "pet@1" || questionId === "transport@4") &&
          getValueFromAnswerValue(value.value) === ""
        ) {
          let baseAnswerData = state.answers[questionId];
          if (
            baseAnswerData &&
            "value" in baseAnswerData &&
            baseAnswerData.value &&
            typeof baseAnswerData.value === "object" &&
            action.payload.data.qaId in baseAnswerData.value
          ) {
            delete baseAnswerData.value[action.payload.data.qaId];
          }
        } else {
          state.answers[questionId] = value;
        }

        updateSumSummary(state);
      }
    );
    builder.addMatcher(
      isAnyOf(asyncUpdateAnswer.rejected, asyncReplaceAnswer.rejected),
      (state, action) => {
        console.log("async answer has be rejected");
        console.log("Action", action.error);
      }
    );
  },
  reducers: {
    setNQId: (state, action: PayloadAction<string | null>) => {
      state.nextQuestionId = action.payload;
    },
    setPQId: (state, action: PayloadAction<string | null>) => {
      state.previousQuestionId = action.payload;
    },
    setQuestion: (
      state,
      { payload }: PayloadAction<TSelectCurrentQuestion>
    ) => {
      state.currentQuestion = payload;
    },
    setCurrentQuestionId: (
      state,
      { payload }: PayloadAction<string | null>
    ) => {
      if (!state.session) return;
      state.session.currentQuestionId = payload;
    },
    updateData: (
      state,
      {
        payload: { multi, ...value },
      }: PayloadAction<
        {
          questionId: string;
          multi?: string;
        } & DataEntry
      >
    ) => {
      if (multi != null && multi != "") {
        if (!state.data[value.questionId]) {
          state.data[value.questionId] = {};
        }
        (state.data[value.questionId] as Record<string, DataEntry>)[multi] =
          value;
      } else {
        state.data[value.questionId] = {
          ...value,
        };
      }

      updateSumSummary(state);
    },
    removeData: (
      state,
      {
        payload,
      }: PayloadAction<{
        questionId: string;
        answerId: string;
      }>
    ) => {
      const qData = state.data[payload.questionId];
      if (!qData) return;
      if (payload.answerId in qData) {
        const _qd = qData as Record<string, DataEntry>;
        delete _qd[payload.answerId];
      } else if (isDataEntry(qData)) {
        delete state.data[payload.questionId];
      }
      updateSumSummary(state);
    },
    nextQuestion: (state) => {
      if (!state.session || !state.nextQuestionId) return;
      state.session.currentQuestionNumber += 1;
      state.session.currentQuestionId = state.nextQuestionId;
    },
    previousQuestion: (state) => {
      if (!state.session) return;
      state.session.currentQuestionNumber -= 1;
      state.session.currentQuestionId = state.previousQuestionId;
    },
  },
});

export const calculatorReducer = lSlice.reducer;
export const calculatorActions = {
  ...lSlice.actions,
  asyncReplaceAnswer: asyncReplaceAnswer,
  asyncUpdateAnswer: asyncUpdateAnswer,
};

async function _postAnswer(data: {
  qId: string;
  qaId: string;
  value: CalculatorStoreState["answers"][string];
}): Promise<PostAnswer> {
  // console.log("postAnswer", data);
  const resp = await fetch("/api/co2-calculator/a", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return await resp.json();
}

function isDataEntry(obj: any): obj is DataEntry {
  return typeof obj === "object" && "questionId" in obj && "data" in obj;
}

function isDataEntryWithRelation(
  obj: any
): obj is DataEntryWithRelation & { factor?: number } {
  return (
    isDataEntry(obj) &&
    "_usesQuestionIdAndAnswerId" in obj &&
    Array.isArray(obj._usesQuestionIdAndAnswerId) &&
    obj._usesQuestionIdAndAnswerId.length > 0
  );
}

function updateSumSummary(state: CalculatorStoreState) {
  const summary: CalculatorStoreState["summary"] = {};
  const reduceFunction = (
    a: number,
    dataEntryOrRecord: CalculatorStoreState["data"][string]
  ): number => {
    let v: number;
    console.log(
      "UpdateSumSummary reduce:",
      a,
      isDataEntryWithRelation(dataEntryOrRecord),
      JSON.parse(JSON.stringify(dataEntryOrRecord))
    );
    if (dataEntryOrRecord.questionId === "energy@1") {
      const h2 = Number(getValueFromAnswerValue(state.answers["home@2"].value));
      let e1 = state.answers["energy@1"]
        ? getValueFromAnswerValue(state.answers["energy@1"].value)
        : 1;
      if (typeof e1 === "string") e1 = 1;
      v = e1 * h2 * (dataEntryOrRecord.data.factor ?? 1);
      console.log("Energy@1", v, e1, h2, dataEntryOrRecord.data.factor);
    } else if (
      dataEntryOrRecord.questionId === "energy@2" &&
      "factor" in dataEntryOrRecord &&
      typeof dataEntryOrRecord.factor === "number" &&
      dataEntryOrRecord.factor !== 0
    ) {
      v = 0;
      const e1 = Number(
        getValueFromAnswerValue(state.answers["energy@1"].value)
      );
      const e1Factor = state.data["energy@1"].data.factor ?? 1;
      const h2 = Number(getValueFromAnswerValue(state.answers["home@2"].value));
      v = e1 * h2 * e1Factor * dataEntryOrRecord.factor;
    } else if (dataEntryOrRecord.questionId === "heating@1") {
      v = 0;
      let factors: number = 1;
      if ("_usesQuestionIdAndAnswerId" in dataEntryOrRecord.data) {
        for (let el of dataEntryOrRecord.data._usesQuestionIdAndAnswerId) {
          const qId = el.questionId;
          const aId = el.answerId;
          if (qId in state.data) {
            const d = state.data[qId] as DataEntry;
            if ("value" in d) {
              factors *= d.value;
            } else if ("factor" in d) {
              factors *= d.factor;
            }
          } else if (qId in state.answers) {
            const answer = state.answers[qId].value;
            if (typeof answer === "number") {
              factors *= answer;
            } else if (typeof answer === "object") {
              if (aId && aId in answer && typeof answer[aId] === "number") {
                factors *= Number(answer[aId]);
              } else {
                for (let _k in answer) {
                  const _v = answer[_k];
                  if (typeof _v === "number") {
                    factors *= _v;
                    break;
                  }
                }
              }
            }
          }
        }
      }
      if ("factor" in dataEntryOrRecord.data)
        factors = factors * dataEntryOrRecord.data.factor;

      let base = 0;
      if (dataEntryOrRecord.data.key === "custom") {
        const _v = state.answers[dataEntryOrRecord.questionId].value;
        if (typeof _v === "number") {
          base = _v;
        } else if (typeof _v === "object") {
          for (let _k in _v) {
            const _vv = _v[_k];
            if (typeof _vv === "number") {
              base = _vv;
              break;
            }
          }
        }
      } else if (
        dataEntryOrRecord.data.key === "unknown" ||
        dataEntryOrRecord.data.key === "no_details"
      ) {
        base = dataEntryOrRecord.data.value;
      } else {
        base = dataEntryOrRecord.data.value;
      }
      v = base * factors;
      console.log("Heating@1", v, base, factors);
    } else if (dataEntryOrRecord.questionId === "transport@3") {
      v = 0;
      const carType = getValueFromAnswerValue(
        state.answers["transport@1"].value
      ) as string;
      const millage = getValueFromAnswerValue(
        state.answers["transport@2"].value
      ) as number;
      const fuelType = state.answers["transport@3"]
        ? (getValueFromAnswerValue(
            state.answers["transport@3"].value
          ) as string)
        : "petrol";
      const dataE2 = state.data["energy@2"];
      const ecoElectric =
        fuelType === "electric" &&
        dataE2 &&
        "factor" in dataE2 &&
        typeof dataE2.factor === "number" &&
        dataE2.factor < 0
          ? 0.1
          : 1;

      const factor = getCarFactorForTypeAndFuel(carType, fuelType);
      v = millage * factor * ecoElectric;
      console.log(
        "Transport@3",
        v,
        carType,
        millage,
        fuelType,
        factor,
        ecoElectric
      );
    } else if (isDataEntryWithRelation(dataEntryOrRecord)) {
      v = 0;
      for (let qa of dataEntryOrRecord._usesQuestionIdAndAnswerId) {
        if (qa.questionId in state.data) {
          const d = state.data[qa.questionId] as DataEntry;
          if ("value" in d) {
            v = (dataEntryOrRecord.factor ?? 1) * d.value;
          } else if ("factor" in d) {
            v += v === 0 ? d.factor : v * d.factor;
          }
        } else if (qa.questionId in state.answers) {
          const answer = state.answers[qa.questionId].value;
          let value: number = 0;
          switch (typeof answer) {
            case "string":
              value = 0;
              break;
            case "number":
              value = answer;
              break;
            case "object":
              for (let _k in answer) {
                const _v = answer[_k];
                if (typeof _v === "number") {
                  value += _v;
                  break;
                }
              }
              break;
            default:
              value = 0;
          }
          v +=
            dataEntryOrRecord.data.factor *
            ("value" in dataEntryOrRecord.data
              ? dataEntryOrRecord.data.value
              : 1) *
            (qa.factor ?? 1) *
            value;
        }
      }
    } else if (isDataEntry(dataEntryOrRecord)) {
      if (
        !Array.isArray(dataEntryOrRecord) &&
        "_usesQuestionId" in dataEntryOrRecord.data &&
        dataEntryOrRecord.data._usesQuestionId in state.answers
      ) {
        const answer =
          state.answers[dataEntryOrRecord.data._usesQuestionId].value;
        let value: number = 0;
        switch (typeof answer) {
          case "string":
            value = 0;
            break;
          case "number":
            value = answer;
            break;
          case "object":
            for (let _k in answer) {
              const _v = answer[_k];
              if (typeof _v === "number") {
                value += _v;
                break;
              }
            }
            break;
          default:
            value = 0;
        }
        v =
          dataEntryOrRecord.data.factor *
          ("value" in dataEntryOrRecord.data
            ? dataEntryOrRecord.data.value
            : 1) *
          value;
      } else if ("value" in dataEntryOrRecord) {
        v = dataEntryOrRecord.value;
      } else {
        v = 0;
      }
    } else {
      v = Object.values(dataEntryOrRecord).reduce(reduceFunction, 0);
    }
    const cat = dataEntryOrRecord.category as CalculatorQuestionCategory;
    if (cat) {
      if (!(cat in summary)) summary[cat] = {};
      summary[cat] = {
        co2e: (summary[cat]!.co2e ?? 0) + v,
      };
      console.log("UpdateSumSummary", v, summary);
    }
    return a + v;
  };

  state.summary = summary;
  if (state.session)
    state.session.yearlyCo2e = Object.values(state.data).reduce(
      reduceFunction,
      0
    );
}
