import clsx from "clsx";
import Image from "next/image";
import Link from "next/link";
import { useTranslations } from "use-intl";
import { useMediaQuery } from "usehooks-ts";
import LogoLong from "../../../public/hellogreenfriends.svg";
import LogoSmall from "../../../public/webclip.png";
import { LayoutConfig } from "../../../types/util";
import useIsActiveLink from "../../hooks/useIsActiveLink";
import useSessionUser from "../../hooks/useSessionUser";
import useUtilTranslations from "../../hooks/useUtilTranslations";
import Co2CodeModal from "../modal/Co2CodeModal";
import styles from "./Header.module.scss";
import { NAV_ITEMS } from "./nav-links";
import NavMenu from "./NavMenu";
import ProfileMenu from "./ProfileMenu";

export type HeaderProps = {} & Pick<
  LayoutConfig,
  "hideNavigation" | "ignoreSession"
>;

function Header({ hideNavigation, ignoreSession }: HeaderProps) {
  const tUtil = useUtilTranslations();
  const matches = useMediaQuery("(max-width: 768px)");
  const matches12 = useMediaQuery("(max-width: 1200px)");
  const { userData } = useSessionUser();
  const t = useTranslations("components.Layout");
  const isActive = useIsActiveLink();

  return (
    <header className={clsx(styles.header)}>
      <div className={styles.headerContent}>
        <Link
          href={userData ? "/dashboard" : "/auth/login"}
          className={styles.logo}
          data-active={isActive("/dashboard") || isActive("/auth/login")}
        >
          {matches12 ? (
            <Image
              src={LogoSmall.src}
              priority
              alt="Logo"
              height={60}
              width={60}
            />
          ) : (
            <Image
              src={LogoLong.src}
              priority
              alt="Logo"
              height={50}
              width={244}
            />
          )}
        </Link>
        {hideNavigation ? (
          <div className={styles.spacer} />
        ) : matches ? (
          <NavMenu />
        ) : (
          <nav className={styles.nav}>
            <Link
              data-active={isActive(NAV_ITEMS.dashboard.path)}
              href={
                NAV_ITEMS.dashboard.disabled ? {} : NAV_ITEMS.dashboard.path
              }
              className={clsx(
                styles.link,
                styles.linkContainer,
                NAV_ITEMS.dashboard.disabled && styles.linkDisabled
              )}
            >
              {t(`nav.${NAV_ITEMS.dashboard.langKey}`)}
              {isActive(NAV_ITEMS.dashboard.path) && (
                <div className={styles.activeBorder} />
              )}
            </Link>
            <Co2CodeModal
              openElement={({ onOpen }) => (
                <div
                  data-active={isActive(NAV_ITEMS.code.path)}
                  onClick={onOpen}
                  className={clsx(
                    styles.link,
                    styles.linkContainer,
                    NAV_ITEMS.code.disabled && styles.linkDisabled
                  )}
                >
                  {t(`nav.${NAV_ITEMS.code.langKey}`)}
                  {isActive(NAV_ITEMS.code.path) && (
                    <div className={styles.activeBorder} />
                  )}
                </div>
              )}
            />
            <Link
              data-active={isActive(NAV_ITEMS.shop.path)}
              href={NAV_ITEMS.shop.disabled ? {} : NAV_ITEMS.shop.path}
              className={clsx(
                styles.link,
                styles.linkContainer,
                NAV_ITEMS.shop.disabled && styles.linkDisabled
              )}
            >
              {t(`nav.${NAV_ITEMS.shop.langKey}`)}
              {isActive(NAV_ITEMS.shop.path) && (
                <div className={styles.activeBorder} />
              )}
            </Link>
            <Link
              data-active={isActive(NAV_ITEMS.abo.path)}
              href={NAV_ITEMS.abo.disabled ? {} : NAV_ITEMS.abo.path}
              className={clsx(
                styles.link,
                styles.linkContainer,
                NAV_ITEMS.abo.disabled && styles.linkDisabled
              )}
            >
              {t(`nav.${NAV_ITEMS.abo.langKey}`)}
              {isActive(NAV_ITEMS.abo.path) && (
                <div className={styles.activeBorder} />
              )}
            </Link>
            <Link
              data-active={isActive(NAV_ITEMS.calc.path)}
              href={NAV_ITEMS.calc.disabled ? {} : NAV_ITEMS.calc.path}
              className={clsx(
                styles.link,
                styles.linkContainer,
                NAV_ITEMS.calc.disabled && styles.linkDisabled
              )}
            >
              {t(`nav.${NAV_ITEMS.calc.langKey}`)}
              {isActive(NAV_ITEMS.calc.path) && (
                <div className={styles.activeBorder} />
              )}
            </Link>
          </nav>
        )}
        {ignoreSession ? null : userData ? (
          <ProfileMenu />
        ) : (
          <Link
            href="/auth/login"
            data-active={isActive("/auth/login")}
            className={clsx(styles.link, styles.login)}
          >
            {tUtil("action.login")}
          </Link>
        )}
      </div>
    </header>
  );
}

Header.Messages = ["components.Layout", ...Co2CodeModal.Messages];

export default Header;
