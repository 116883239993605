import {
  createContext,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from "react";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";

type ConfirmModalContextValue = {
  openConfirmModal: (
    content: ReactNode,
    description?: ReactNode,
    isDestructiveAction?: boolean
  ) => Promise<boolean>;
};

export const ConfirmModalContext = createContext<ConfirmModalContextValue>({
  openConfirmModal: async () => false,
});

const initialState: {
  isModalOpen: boolean;
  modalResolve: Function | undefined;
  modalContent: ReactNode;
  modalDescription: ReactNode;
  isDestructiveAction?: boolean;
} = {
  isModalOpen: false,
  modalContent: undefined,
  modalDescription: undefined,
  modalResolve: undefined,
  isDestructiveAction: undefined,
};

export const ConfirmModalProvider = ({ children }: PropsWithChildren<{}>) => {
  const [modalState, setModalState] = useState(initialState);

  const openConfirmModal = useCallback(
    async (
      content: ReactNode,
      description?: ReactNode,
      isDestructiveAction?: boolean
    ) => {
      return new Promise<boolean>((resolve) => {
        setModalState({
          isModalOpen: true,
          modalContent: content,
          modalDescription: description,
          modalResolve: resolve,
          isDestructiveAction: isDestructiveAction,
        });
      });
    },
    []
  );

  const onClose = (success: boolean) => {
    setModalState((prevState) => ({
      ...prevState,
      isModalOpen: false,
    }));
    modalState.modalResolve?.(success);
  };

  return (
    <ConfirmModalContext.Provider
      value={{
        openConfirmModal: openConfirmModal,
      }}
    >
      <ConfirmModal
        content={modalState.modalContent}
        description={modalState.modalDescription}
        destructiveAction={modalState.isDestructiveAction}
        isModalOpen={modalState.isModalOpen}
        onClose={onClose}
      />
      {children}
    </ConfirmModalContext.Provider>
  );
};
