import { ReactNode } from "react";
import styles from "./SmallText.module.scss";

export type SmallTextProps = ReactNode;

function SmallText(children: SmallTextProps) {
  return <span className={styles.s}>{children}</span>;
}

export default SmallText;
