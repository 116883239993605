import { useRouter } from "next/router";

export default function useIsActiveLink() {
  const router = useRouter();
  const isActive: (pathname: string, explicit?: boolean) => boolean = (
    pathname,
    explicit = false
  ) =>
    explicit
      ? router.pathname === pathname
      : router.pathname.startsWith(pathname);
  return isActive;
}
