import { WebStorage } from "redux-persist/es/types";

export const SessionAPIStorage: WebStorage = {
  getItem: async (key: string) => {
    if (typeof window != "object") return null;
    if (key !== "persist:calculator") return null;

    const resp = await fetch("/api/co2-calculator/s/storage");
    if (resp.ok) {
      return await resp.text();
    } else {
      return null;
    }
  },
  setItem: async (key: string, value: string) => {
    if (typeof window != "object") return;
    const resp = await fetch("/api/co2-calculator/s/storage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(value),
    });
    if (process.env.NODE_ENV !== "production" && !resp.ok) {
      console.error(
        "Problem setting calculator session",
        resp.status,
        resp.statusText
      );
    }
  },
  removeItem: async (key: string) => {
    if (typeof window != "object") return;
    if (key !== "persist:calculator") return;

    const resp = await fetch("/api/co2-calculator/s/storage", {
      method: "DELETE",
    });
    if (process.env.NODE_ENV !== "production" && !resp.ok) {
      console.error(
        "Problem removing calculator session",
        resp.status,
        resp.statusText
      );
    }
  },
};
