import {
  faCarBus,
  faDog,
  faHouse,
  faPotFood,
} from "@fortawesome/pro-solid-svg-icons";
import { faBagsShopping } from "@fortawesome/pro-solid-svg-icons/faBagsShopping";
import { CalculatorQuestionCategory, Translation } from "@prisma/client";
import { useLocale } from "use-intl";
import { CalculatorAnswerTypes } from "../../types/Co2CalculatorAnswerTypes";
import useUtilTranslations from "../hooks/useUtilTranslations";

export const CarType = {
  SMALL: "SMALL",
  MEDIUM: "MEDIUM",
  LIMOUSINE: "LIMOUSINE",
} as const;

export function usePrismaTranslations(translations: Translation[]) {
  const locale = useLocale();
  const tUtil = useUtilTranslations();

  const hint = ({
    key,
    hint,
  }: Pick<CalculatorAnswerTypes.BaseRadioData, "key" | "hint">) => {
    if (typeof hint === "boolean") {
      return translations.find(
        (qt) => `hint_${key}` === qt.key && qt.language === locale
      )?.value;
    } else {
      return hint;
    }
  };
  const label = ({
    key,
    label,
  }: Pick<CalculatorAnswerTypes.BaseRadioData, "key" | "label">) => {
    return (
      translations.find((t) => t.key === key && t.language === locale)?.value ??
      translations.find((t) => t.key === label && t.language === locale)
        ?.value ??
      label ??
      tUtil(key as any)
    );
  };
  return {
    hint: hint,
    label: label,
  };
}

export function hasAnswerDataOptions(
  obj: any
): obj is CalculatorAnswerTypes.BaseRadioData & {
  options: CalculatorAnswerTypes.RadioOption[];
} {
  return (
    "options" in obj &&
    Array.isArray(obj.options) &&
    (obj.options as any[]).every((o) => "key" in o)
  );
}

export function getIconForCategory(category: CalculatorQuestionCategory) {
  switch (category) {
    case CalculatorQuestionCategory.HOME:
      return faHouse;
    case CalculatorQuestionCategory.FOOD:
      return faPotFood;
    case CalculatorQuestionCategory.PET:
      return faDog;
    case CalculatorQuestionCategory.GOODS:
      return faBagsShopping;
    case CalculatorQuestionCategory.TRANSPORTATION:
      return faCarBus;
    case CalculatorQuestionCategory.FREETIME_CULTURE:
      return faBagsShopping;
    default:
      return faHouse;
  }
}

export function getValueFromAnswerValue(
  value: CalculatorAnswerTypes.BaseUserAnswer["value"]
) {
  if (typeof value === "string") {
    return Number(value);
  } else if (typeof value === "number") {
    return value;
  } else if (typeof value === "object") {
    if (Object.keys(value).find((k) => k.endsWith("_custom"))) {
      return Number(
        value[Object.keys(value).find((k) => k.endsWith("_custom"))!]
      );
    }
    // noinspection LoopStatementThatDoesntLoopJS
    for (let key in value) {
      if (key.endsWith("_custom")) {
        return Number(value[key]);
      }
      // if (typeof value[key] === "number") {
      return value[key];
      // }
    }
  }
  return 0;
}

export function getCarFactorForTypeAndFuel(type: string, fuel: string) {
  switch (fuel) {
    case "petrol":
      switch (type) {
        case CarType.SMALL:
          return 0.125;
        case CarType.MEDIUM:
          return 0.147;
        case CarType.LIMOUSINE:
          return 0.184;
      }
      break;
    case "diesel":
      switch (type) {
        case CarType.SMALL:
          return 0.144;
        case CarType.MEDIUM:
          return 0.185;
        case CarType.LIMOUSINE:
          return 0.221;
      }
      break;
    case "plug_in_hybrid":
      switch (type) {
        case CarType.SMALL:
          return 0.065;
        case CarType.MEDIUM:
          return 0.10999;
        case CarType.LIMOUSINE:
          return 0.1515;
      }
      break;
    case "bio_fuel":
      switch (type) {
        case CarType.SMALL:
          return 0.00702;
        case CarType.MEDIUM:
          return 0.00636;
        case CarType.LIMOUSINE:
          return 0.00392;
      }
      break;
    case "electric":
      switch (type) {
        case CarType.SMALL:
          return 0.048;
        case CarType.MEDIUM:
          return 0.0621;
        case CarType.LIMOUSINE:
          return 0.139;
      }
      break;
    case "gas":
      switch (type) {
        case CarType.SMALL:
          return 0.147;
        case CarType.MEDIUM:
          return 0.177;
        case CarType.LIMOUSINE:
          return 0.215;
      }
      break;
  }
  return 1;
}
