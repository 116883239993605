import { signOut } from "next-auth/react";
import { useCallback } from "react";
import { useAuthContext } from "../context/AuthContext";
import { LogoutAction } from "../redux/actions";
import { ResetApi } from "../redux/apiSlice";
import { useAppDispatch } from "../redux/hooks";

function useLogout() {
  const dispatch = useAppDispatch();
  const { c } = useAuthContext();

  // noinspection UnnecessaryLocalVariableJS
  const logout = useCallback(async () => {
    await signOut({ redirect: true, callbackUrl: c.logout ?? "/auth/login" });
    dispatch(LogoutAction());
    dispatch(ResetApi());
  }, [c, dispatch]);

  return logout;
}

export default useLogout;
