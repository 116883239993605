import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@szhsin/react-menu";
import clsx from "clsx";
import { useTranslations } from "use-intl";
import Co2CodeModal from "../modal/Co2CodeModal";
import { NAV_ITEMS } from "./nav-links";
import NavMenuLink from "./NavMenuLink";
import styles from "./ProfileMenu.module.scss";

export type NavMenuProps = {};

function NavMenu({}: NavMenuProps) {
  const t = useTranslations("components.Layout");

  return (
    <Menu
      align="center"
      direction="bottom"
      transition
      menuButton={
        <FontAwesomeIcon
          className={styles.buttonIcon}
          size="xl"
          icon={faBars}
          style={{ cursor: "pointer" }}
        />
      }
    >
      <NavMenuLink navItem={NAV_ITEMS.dashboard}>
        {t(`nav.${NAV_ITEMS.dashboard.langKey}`)}
      </NavMenuLink>
      <Co2CodeModal
        openElement={({ onOpen }) => (
          <MenuItem
            disabled={NAV_ITEMS.code.disabled}
            onClick={onOpen}
            className={clsx(
              styles.link,
              styles.linkContainer,
              NAV_ITEMS.code.disabled && styles.linkDisabled
            )}
          >
            {t(`nav.${NAV_ITEMS.code.langKey}`)}
          </MenuItem>
        )}
      />
      <NavMenuLink navItem={NAV_ITEMS.shop}>
        {t(`nav.${NAV_ITEMS.shop.langKey}`)}
      </NavMenuLink>
      <NavMenuLink navItem={NAV_ITEMS.abo}>
        {t(`nav.${NAV_ITEMS.abo.langKey}`)}
      </NavMenuLink>
      <NavMenuLink navItem={NAV_ITEMS.calc}>
        {t(`nav.${NAV_ITEMS.calc.langKey}`)}
      </NavMenuLink>
    </Menu>
  );
}

export default NavMenu;
