import { createContext, useContext } from "react";
import { LayoutConfig } from "../../types/util";
import { noop } from "../util";

export type LayoutContextState = {
  config: LayoutConfig | undefined;
  update: (
    key: keyof LayoutConfig,
    value: LayoutConfig[keyof LayoutConfig]
  ) => void;
};
export const LayoutContext = createContext<LayoutContextState>({
  config: undefined,
  update: noop,
});

export const useLayoutContext = () => useContext(LayoutContext);

export default LayoutContext;
