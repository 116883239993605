import { FocusableItem } from "@szhsin/react-menu";
import Link, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";
import { NAV_ITEMS } from "./nav-links";

export type NavLinkProps = {
  navItem: (typeof NAV_ITEMS)[keyof typeof NAV_ITEMS];
} & Omit<LinkProps, "href" | "data-active">;

function NavMenuLink({ children, navItem }: PropsWithChildren<NavLinkProps>) {
  return (
    <FocusableItem disabled={navItem.disabled}>
      {({ ref, closeMenu }) => (
        <Link
          ref={ref}
          href={navItem.disabled ? {} : navItem.path}
          onClick={({ detail }) =>
            closeMenu(detail === 0 ? "Enter" : undefined)
          }
        >
          {children}
        </Link>
      )}
    </FocusableItem>
  );
}

export default NavMenuLink;
