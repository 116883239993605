import { createContext, useContext } from "react";
import { noop } from "../util";

export type AuthContextState = {
  c: Record<"logout" | "login", string | undefined>;
  updateCallback(key: "logout" | "login", value: string | undefined): void;
};
export const AuthContext = createContext<AuthContextState>({
  c: { logout: undefined, login: undefined },
  updateCallback: noop,
});

export const useAuthContext = () => useContext(AuthContext);

export default AuthContext;
