import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { ApiMiddleware, ApiReducer, ApiReducerPath } from "./apiSlice";
import { calculatorReducer } from "./co2calculatorSlice";
import { SessionAPIStorage } from "./sessionAPIStorage";

const calcPersistedReducer = persistReducer(
  {
    key: "calculator",
    storage: SessionAPIStorage,
    throttle: 100,
    debug: process.env.NODE_ENV !== "production",
    serialize: false,
    // @ts-ignore
    // noinspection JSUnusedGlobalSymbols
    deserialize: (data) => {
      if (typeof data === "object") return data;
      return JSON.parse(data);
    },
  },
  calculatorReducer
);

const rootReducer = combineReducers({
  [ApiReducerPath]: ApiReducer,
  calculator: calcPersistedReducer,
});

const persistedReducer = persistReducer(
  {
    key: "hgf-app",
    storage,
    whitelist: [],
    blacklist: [ApiReducerPath, "calculator"],
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(ApiMiddleware),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
