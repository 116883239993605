import { faX } from "@fortawesome/pro-regular-svg-icons/faX";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslations } from "use-intl";
import { useState } from "react";
import { useAddCo2CodeMutation } from "../../redux/apiSlice";
import Button from "../Button/Button";
import BaseModal from "./BaseModal";
import styles from "./Co2CodeModal.module.scss";

export type Co2CodeProps = {
  openElement?: ({}: { onOpen: () => void }) => JSX.Element;
  defaultValue?: string;
};

function Co2CodeModal({
  defaultValue,
  openElement: OpenElement,
}: Co2CodeProps) {
  const t = useTranslations("components.Co2CodeModal");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(!!defaultValue);
  const [code, setCode] = useState(defaultValue ?? "");
  const [addCo2Code, { isLoading }] = useAddCo2CodeMutation();
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const handleRedeemClick = async () => {
    setErrorMsg(undefined);
    const resp = await addCo2Code({ code: code });

    //console.log(resp);
    if ("error" in resp) {
      console.log(resp);
      const e = resp.error as any;
      setErrorMsg(
        e.data.msgKey ? t(e.data.msgKey) : e.data.message ?? t("notFound")
      );
    } else {
      setCode("");
      setIsModalOpen(false);
    }
  };

  return (
    <>
      {OpenElement && <OpenElement onOpen={() => setIsModalOpen(true)} />}
      <BaseModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <div className={styles.header}>{t.rich("header")}</div>
        <input
          value={code}
          onChange={(ev) => {
            setCode(ev.target.value);
          }}
          className={styles.input}
          placeholder={t("input_placeholder")}
        />
        {errorMsg && <div className={styles.errorMessage}>{errorMsg}</div>}
        <Button color="green" loading={isLoading} onClick={handleRedeemClick}>
          {t("redeem")}
        </Button>
        <div
          className={styles.closeButton}
          onClick={() => setIsModalOpen(false)}
        >
          <FontAwesomeIcon icon={faX} size="xl" color="#fff" />
        </div>
      </BaseModal>
    </>
  );
}

Co2CodeModal.Messages = ["components.Co2CodeModal"];

export default Co2CodeModal;
