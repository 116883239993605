import { faXmark } from "@fortawesome/pro-regular-svg-icons/faXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import ReactModal from "react-modal";
import styles from "./BaseModal.module.scss";

export type BaseModalProps = ReactModal.Props;

function BaseModal({
  children,
  className,
  overlayClassName,
  onRequestClose,
  ...props
}: BaseModalProps) {
  return (
    <ReactModal
      closeTimeoutMS={200}
      onRequestClose={onRequestClose}
      overlayClassName={clsx(styles.drop, overlayClassName)}
      className={clsx(styles.modal, className)}
      preventScroll
      {...props}
    >
      {children}
      <div className={styles.closeButton} onClick={onRequestClose}>
        <FontAwesomeIcon icon={faXmark} color="#fff" />
      </div>
    </ReactModal>
  );
}

export default BaseModal;
